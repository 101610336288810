import React from "react";
import IMG from "../../../assets/data/roofCard1.png";
import { Link } from "react-router-dom";
export default function CardTop() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">
              GAF Premium Shingles-Show your style with America's #1 shingles
            </p>
            <p className="cardSubTxt">
              When you choose GAF, you're in good company. More Americans have
              relied on GAF shingles than any other brand. With the strongest
              warranties in the industry, you can have peace of mind knowing you
              are covered. From the Grand Sequoia with its wood shake look,
              Royal Sovereign's beauty and performance, to the #1 selling
              shingles in North America, Timberline HDZ, we have the perfect
              shingles for your house.
            </p>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/lifetime-shingles"
            >
              <button className="blueBtn">VIEW ALL SHINGLES</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
