import React from "react";
import "./TopCard.scss";
export default function TopCard(props) {
  console.log("props", props);
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={props.cardImg} alt="cardImg" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxtS1">{props.title}</p>
            <p className="cardSubTxt">{props.txt1}</p>
            <p className="cardSubTxt">{props.txt2}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
