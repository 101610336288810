import React from "react";
import "./CardBottom.scss";
import IMG from "../../../assets/home/home3.png";
import { Link } from "react-router-dom";

export default function CardBottom() {
  return (
    <div className="cardBase">
      <div className="cardContent">
        <div className="cardLeftGrid1">
          <div className="imgBase">
            <img src={IMG} alt="cardImg" className="cardImg" />
          </div>
        </div>
        <div className="cardRightGrid">
          <div className="textBase">
            <p className="cardTitleTxt1">GAF Premium Roofing Shingles</p>
            <p className="cardSubTxtL">
              Nothing Protects Quite Like A Roof, And no warranties can cover
              your home quite like GAF's Lifetime Roofing System
            </p>
            <p className="cardSubTxt">
              As certified GAF Installers, we can educate you about the best
              roofing system and warranty for your home. Not all roofs need to
              be replaced and after your FREE roof inspection, we may find you
              only need minor repairs. We will also assess storm damage and work
              with you to file a claim with your insurance company when there is
              hail and wind damage.
            </p>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/roofing"
            >
              <button className="blueBtn">MORE</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
