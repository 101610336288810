import React from "react";
import Layout from "./layout/Layout";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Restoration.Roofing.Remodeling | Restoration and Roofing in Texas
        </title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      </Helmet>
      <Layout />
    </div>
  );
}
