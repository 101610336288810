import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import IC1 from "../../assets/social/fb.png";
import IC2 from "../../assets/social/in.png";
import IC3 from "../../assets/social/lk.png";
import IC4 from "../../assets/social/yt.png";
import { HashLink } from "react-router-hash-link";
export default function Footer() {
  const reviewUrl =
    "https://www.google.com/search?q=Restoration+Roofing+%26+Remodeling+LLC&sca_esv=cbee88a0659955a3&sca_upv=1&rlz=1C1GCEA_enIN1033IN1033&sxsrf=ADLYWIKmsSb4UL5dyTP440vSg4BbgptqNQ%3A1719492642511&ei=ImB9ZuXqHqbgseMPy4uQ-A4&ved=0ahUKEwjlg5qh6fuGAxUmcGwGHcsFBO8Q4dUDCBA&uact=5&oq=Restoration+Roofing+%26+Remodeling+LLC&gs_lp=Egxnd3Mtd2l6LXNlcnAiJFJlc3RvcmF0aW9uIFJvb2ZpbmcgJiBSZW1vZGVsaW5nIExMQzIFEAAYgAQyBhAAGBYYHjILEAAYgAQYhgMYigUyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKMfUO4KWMUdcAJ4AZABAJgB5gGgAeYBqgEDMi0xuAEDyAEA-AEB-AECmAIDoALvAagCEcICBxAjGCcY6gLCAhoQLhiABBjjBBi0AhjHARjpBBjqAhivAdgBAcICFBAAGIAEGOMEGLQCGOkEGOoC2AEBwgIWEAAYAxi0AhjlAhjqAhiMAxiPAdgBApgDBroGBggBEAEYAboGBggCEAEYC5IHBTIuMC4xoAf-BA&sclient=gws-wiz-serp#ip=1&lrd=0x8647337550c94b33:0x7e7a7b98d49ddbdc,1,,,,";
  const currentYear = new Date().getFullYear();
  return (
    <div className="footerBase">
      <div className="footerContent">
        <p className="contactHomeSubTxtFoot">
          Top Roofing Company in Texas.We are your trusted partner in elevating
          the beauty and functionality of your home
        </p>
        <div className="footerTop">
          <div className="footerGrid">
            <p className="footerHeadTxt">Quick Links</p>
            <div className="footerGridFlexContent">
              <div className="footerGridFlex">
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/home"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Home</p>
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/roofing"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Roofing</p>
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/storm-service"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Storm Services</p>
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/financing"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Financing</p>
                </Link>
              </div>
              <div className="footerGridFlex">
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/videos"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Videos</p>
                </Link>

                <HashLink
                  smooth
                  to="/about-us#reviews"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Reviews</p>
                </HashLink>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/about-us"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">About</p>
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/contact-us"
                  style={{ textDecoration: "none" }}
                >
                  <p className="footerLinkTxt">Contact</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="footerGridFollow">
            <p className="footerHeadTxt">Follow us</p>

            <div className="footerIcons">
              <a
                href="https://www.facebook.com/RestorationRoofingRemodeling"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IC1} alt="logo" className="socialIcon" />
              </a>
              <a
                href="https://www.instagram.com/restorationroofingremodeling/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IC2} alt="logo" className="socialIcon" />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IC3} alt="logo" className="socialIcon" />
              </a>
              <a
                href="https://www.youtube.com/@RRRHoustonTX/videos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IC4} alt="logo" className="socialIcon" />
              </a>
            </div>
            <a href={reviewUrl} style={{ textDecoration: "none" }}>
              <div className="heroDataBottomDiv">
                <div className="heroDataLeft">
                  <p className="heroSubTxt">Google Rating</p>
                </div>
                <div className="heroDataRight">
                  <img
                    src="https://www.pngplay.com/wp-content/uploads/6/Golden-5-Star-Rating-PNG.png"
                    alt="rating"
                    className="ratingStarImg"
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="footerGrid">
            <p
              className="footerHeadTxt"
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "10px",
              }}
            >
              Contact Us
            </p>
            <p className="footerLinkTxt">Restoration Roofing & Remodeling</p>
            <p className="footerLinkTxt">6902 fm 2920</p>
            <p className="footerLinkTxt">Suite 2</p>
            <p className="footerLinkTxt">Spring, TX 77379</p>
            <p className="footerLinkTxt">Phone: (832) 639-8756</p>
          </div>
        </div>
        <div className="footerBottomDivFollow">
          <div className="folloWRespDiv">
            <p className="footerHeadTxt">Follow us</p>

            <div className="footerIcons">
              <a href="https://www.facebook.com/">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/fb.png"
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://www.linkedin.com/">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/insta.png"
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://twitter.com/">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/twitter.png"
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://www.instagram.com/">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/in.png"
                  alt="logo"
                  className="socialIcon"
                />
              </a>
            </div>
            <a href={reviewUrl} style={{ textDecoration: "none" }}>
              <div className="heroDataBottomDiv">
                <div className="heroDataLeft">
                  <p className="heroSubTxt">Google Rating</p>
                </div>
                <div className="heroDataRight">
                  <img
                    src="https://www.pngplay.com/wp-content/uploads/6/Golden-5-Star-Rating-PNG.png"
                    alt="rating"
                    className="ratingStarImg"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="footerBottomDiv">
          <p className="copyTxt">
            &copy; {currentYear} RRR. All Rights Reserved
          </p>
          <a
            href="https://pennonn.com/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <p className="copyTxt">Powered By: Pennonn Inc</p>
          </a>
        </div>
      </div>
    </div>
  );
}
